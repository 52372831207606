import React, { useEffect, useRef, useState } from "react";
import "./adminOrderItemHistory.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import TruncateCell from "../../SharedComponents/truncateCell/truncateCell";
import moment from "moment";
import {
  updateOrderItem,
  orderItemLists,
  changeOrderItemName,
  updateIgnoreNotification,
} from "../../Services/orderService";
import { errorToast, successToast } from "../../Services/toastService";
import FilterSearch from "../../SharedComponents/filterSearch/filterSearch";
import Pagination from "react-bootstrap/Pagination";
import * as XLSX from "xlsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setTable } from "../../State/store.js";
import HistoryComment from "../../SharedComponents/historyComment/historyComment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLocale } from "../../lib/customHooks/useLocale.jsx";
import { Dropdown } from "react-bootstrap";
import TrackingDialog from "../../SharedComponents/trackingDialog/trackingDialog.jsx";
import { getShipment } from "../../Services/shipmentService.js";
import InfiniteScroll from "react-infinite-scroll-component";

const AdminOrderItemHistory = () => {
  const { t } = useLocale("common");
  const [changeNameLoading, setChangeNameLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const [orders, setOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const token = useSelector((state) => state.token);
  const [allExpanded, setAllExpanded] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const role = useSelector((state) => state.user.role);
  const [searchObj, setSearchObj] = useState([]);
  // const [comment, setComment] = useState("");
  const filterSearchRef1 = useRef(null);
  const filterSearchRef2 = useRef(null);
  const filterSearchRef3 = useRef(null);
  const filterSearchRef4 = useRef(null);
  const filterSearchRef5 = useRef(null);
  const filterSearchRef6 = useRef(null);
  const filterSearchRef7 = useRef(null);
  const filterSearchRef8 = useRef(null);
  const filterSearchRef9 = useRef(null);
  const filterSearchRef10 = useRef(null);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState({
    createdAt: "desc",
  });
  const [toggleCurr, setToggleCurr] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const navigate = useNavigate();
  const [tableReorderModal, setTableReorderModal] = useState(false);
  const tableObj = useSelector((state) => state.table);
  const dispatch = useDispatch();

  const [tableReorderState, setTableReorderState] = useState(tableObj);
  const [changeOrderItemNameModal, setChangeOrderItemNameModal] =
    useState(false);
  const [changeOrderItem, setChangeOrderItem] = useState({
    id: "",
    oldName: "",
    productName: "",
    isSubstitute: true,
  });
  const [orderItemHistoryInfo, setOrderItemHistoryInfo] = useState(false);
  const [infoObj, setInfoObj] = useState(null);
  const [ShipmentTypeList, setShipmentTypeList] = useState([])

  let items = [];
  let paginationNum = [10, 25, 50];
  paginationNum.forEach((number) => {
    items.push(
      <Pagination.Item
        key={number}
        active={number === limit}
        onClick={() => setLimit(number)}
      >
        {number}
      </Pagination.Item>
    );
  });

  var refArray = [
    filterSearchRef1,
    filterSearchRef2,
    filterSearchRef3,
    filterSearchRef4,
    filterSearchRef5,
    filterSearchRef6,
    filterSearchRef7,
    filterSearchRef8,
    filterSearchRef9,
    filterSearchRef10,
  ];

  const handleArrowIconClicked = (index, direction) => {
    const updatedFilterObj = [...tableObj];
    if (direction === "left" && index > 0) {
      [updatedFilterObj[index], updatedFilterObj[index - 1]] = [
        updatedFilterObj[index - 1],
        updatedFilterObj[index],
      ];
    } else if (direction === "right" && index < tableObj.length - 1) {
      [updatedFilterObj[index], updatedFilterObj[index + 1]] = [
        updatedFilterObj[index + 1],
        updatedFilterObj[index],
      ];
    }
    dispatch(
      setTable({
        table: updatedFilterObj,
      })
    );
  };

  const resetFilter = () => {
    if (id) {
      navigate("/orderItemHistory");
    }
    setSearchObj([]);
    setOrderBy({ createdAt: "desc" });
    setLimit(10);
    filterSearchRef1.current && filterSearchRef1.current.resetFilter();
    filterSearchRef2.current && filterSearchRef2.current.resetFilter();
    filterSearchRef3.current && filterSearchRef3.current.resetFilter();
    filterSearchRef4.current && filterSearchRef4.current.resetFilter();
    filterSearchRef5.current && filterSearchRef5.current.resetFilter();
    filterSearchRef6.current && filterSearchRef6.current.resetFilter();
    filterSearchRef7.current && filterSearchRef7.current.resetFilter();
    filterSearchRef8.current && filterSearchRef8.current.resetFilter();
    filterSearchRef9.current && filterSearchRef9.current.resetFilter();
    filterSearchRef10.current && filterSearchRef10.current.resetFilter();
  };

  let { id } = useParams();

  const [updateData, setUpdateData] = useState({
    id: "",
    shipmentType: "",
    consigneeName: "",
    deliveryAddress: "",
    pincode: "",
    country: "",
    phone: "",
    trackingId: "",
  });

  const handleUpdateModal = () => {
    setUpdateModal(!updateModal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await updateOrderItem(token, updateData);
      if (res.status === 200) {
        const updatedOrders = orders.map((order) => {
          if (order.id === updateData.id) {
            return { ...order, ...updateData };
          }
          return order;
        });
        setOrders(updatedOrders);
        setUpdateData({
          id: "",
          shipmentType: "",
          consigneeName: "",
          deliveryAddress: "",
          pincode: "",
          country: "",
          phone: "",
          trackingId: "",
        });
        handleUpdateModal();
        successToast("Updated Successfully");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const orderItems = async () => {
    setTableLoading(true);
    try {
      if (id) {
        let newObj = [
          {
            column: "id",
            type: "contains",
            value: id,
          },
        ];
        id = undefined;
        const res = await orderItemLists(token, null, newObj, limit, orderBy);
        const orders = res.data.orders;
        const count = res.data.totalCount;
        setOrders(orders);
        setTotalCount(count);
      } else {
        const res = await orderItemLists(
          token,
          null,
          searchObj,
          limit,
          orderBy
        );
        const orders = res.data.orders;
        const count = res.data.totalCount;
        setOrders(orders);
        setTotalCount(count);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  };

  const updateItem = (item) => {
    setUpdateData({
      id: item.id,
      shipmentType: item.shipmentType,
      consigneeName: item.consigneeName,
      deliveryAddress: item.deliveryAddress,
      pincode: item.pincode,
      country: item.country,
      phone: item.phone,
      trackingId: item.trackingId,
    });
    handleUpdateModal();
  };

  const handleLoadMore = async () => {
    try {
      const response = await orderItemLists(
        token,
        orders.length,
        searchObj,
        limit,
        orderBy
      );
      const additionalOrders = response.data.orders;
      const totalCount = response.data.totalCount;
      const newTable = [...orders, ...additionalOrders];
      setOrders(newTable);
      setTotalCount(totalCount);
    } catch (error) {
      console.error("Failed to fetch orders.", error);
    }
  };

  const filterSearch = async (obj, isSelected) => {
    let current = searchObj;
    current = current.filter((item) => item.column !== obj.column);
    if (isSelected) {
      setSearchObj(current);
    } else {
      current.push(obj);
      setSearchObj(current);
    }
  };

  const handleDownloadList = async () => {
    try {
      const activeColumns = tableObj.filter((item) => !item.disabled);
      const data = [
        activeColumns.map((item) => item.key),
        ...orders.map((order) => {
          return activeColumns.map((item) => {
            if (item.value === "createdAt") {
              return moment(order.createdAt).format("YYYY-MM-DD HH:mm");
            } else if (item.value === "price") {
              return order.price ? (
                toggleCurr ? (
                  `${parseFloat(order.priceInINR).toFixed(2)} INR`
                ) : (
                  `${parseFloat(order.price).toFixed(2)} ${order.client.Wallet.currency.code}`
                )
              ) : (
                "-"
              );
            } else {
              return order[item.value];
            }
          });
        }),
      ];

      const ws = XLSX.utils.aoa_to_sheet(data); // Convert data array to worksheet
      const wb = XLSX.utils.book_new(); // Create workbook
      XLSX.utils.book_append_sheet(wb, ws, "Order Data"); // Add worksheet to workbook
      XLSX.writeFile(wb, "order-data.xlsx"); // Trigger file download
    } catch (err) {
      console.log(err);
    }
  };

  const getShipmentData = async () => {
    try {
      const res = await getShipment(token);
      let codeArr = [];
      res.data.forEach((item) => {
        codeArr.push(item.code);
      });
      setShipmentTypeList(codeArr);
    } catch (err) {
      errorToast("something went wrong");
    }
  };

  useEffect(() => {
    orderItems();
    // eslint-disable-next-line
  }, [searchObj, limit, orderBy]);

  useEffect(() => {
    getShipmentData();
    // eslint-disable-next-line
  }, [])

  const handlePositionChange = (startIndex, targetIndex) => {
    const updatedTable = [...tableReorderState];
    // remove the element from the current index
    const [removedElement] = updatedTable.splice(startIndex, 1);
    // insert the current removed element at targetIndex
    updatedTable.splice(targetIndex, 0, removedElement);
    setTableReorderState(updatedTable);
  };

  const handleTableSetting = () => {
    if (!tableReorderState.length) {
      setTableReorderModal(false);
      return;
    }
    dispatch(
      setTable({
        table: tableReorderState,
      })
    );
    setTableReorderModal(false);
  };

  const handleDisabledButton = (i) => {
    const updatedData = tableReorderState.map((item) =>
      item.key === i.key ? { ...item, disabled: !item.disabled } : item
    );
    setTableReorderState(updatedData);
  };

  const handleIgnoreAlertsToggle = async (orderItemId, value) => {
    try {
      if (window.confirm("Sure want to ignore alert for this item") === true) {
        // const currentState = toggleIgnoreAlerts;
        const data = {
          id: orderItemId,
          ignoreNotification: value,
        };

        const res = await updateIgnoreNotification(token, data);
        if (res.status === 200) {
          const updatedData = orders.map((item) => {
            if (item.id === orderItemId) {
              return {
                ...item,
                ignoreNotification: value
              }
            }
            return item
          })
          setOrders(updatedData)
          successToast(res.data.message);
        }
      }
    } catch (err) {
      errorToast("Something went wrong");
    }
  };

  const handleChangeOrderItemBtn = (item) => {
    setChangeOrderItem({
      id: item.id,
      oldName: item.productName,
      isSubstitute: true,
    });
    setChangeOrderItemNameModal(true);
  };

  const handleChangeOrderItem = async () => {
    try {
      setChangeNameLoading(true);
      if (!changeOrderItem.productName) {
        errorToast("Name is required");
        return;
      }
      if (changeOrderItem.productName === changeOrderItem.oldName) {
        errorToast("New Item can't be same as old Item");
        return;
      }
      const res = await changeOrderItemName(token, changeOrderItem);
      if (res.status === 200) {
        const updatedData = orders.map((item) => {
          if (item.id === changeOrderItem.id) {
            return { ...item, productName: changeOrderItem.productName };
          }
          return item;
        });
        setOrders(updatedData);
        setChangeOrderItemNameModal(false);
        setChangeOrderItem({
          id: "",
          oldName: "",
          productName: "",
          isSubstitute: true,
        });
        successToast(res.data.message);
      }
    } catch (err) {
      return errorToast(err.response?.data?.message || "Something went wrong");
    } finally {
      setChangeNameLoading(false);
    }
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = [...tableReorderState];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTableReorderState(items);
  }

  return (
    <Container fluid>
      <div>
        <h4 className="my-4">{t("Order Item History")}</h4>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <Button
              variant="outline-danger"
              id="button-addon2"
              onClick={resetFilter}
            >
              <i className="fa-solid fa-rotate-left"></i>
            </Button>
            {["admin"].includes(role) && (
              <>
                <Button
                  variant="outline-secondary"
                  id="button-addon3"
                  onClick={() => setTableReorderModal(true)}
                  className="ms-2"
                >
                  Table Settings
                </Button>
                <div className="ms-2">
                  <Button onClick={handleDownloadList}>Download List</Button>
                </div>
                <Form.Check
                  className="ms-2"
                  type="switch"
                  id="inr-switch2"
                  label="Price In Inr"
                  checked={toggleCurr}
                  onChange={() => {
                    setToggleCurr(!toggleCurr);
                  }}
                />
              </>
            )}
          </div>
          <div>
            <Pagination>{items}</Pagination>
          </div>
        </div>
          <div className="my-2 text-center">
            <b>{orders && (<div>{orders.length} / {totalCount}</div>)}</b>
          </div>
      </div>
      <InfiniteScroll
        next={handleLoadMore}
        dataLength={orders.length || 0}
        hasMore={orders.length !== totalCount}
        loader={
          <div className="text-center mt-4">
            <p>Loading...</p>
          </div>
        }
        endMessage={
          <div style={{ textAlign: 'center', marginTop: "20px" }}>
            <b>{orders && (<div>{orders.length} / {totalCount}</div>)}</b>
          </div>
        }
      >
        <Table bordered hover className="mb-2" responsive>
          <thead>
            <tr>
              {tableObj?.map(
                (item, i) =>
                  (["admin", "accountant"].includes(role) ||
                    item.key !== "Price") && (
                    <FilterSearch
                      className={item.disabled ? "d-none" : ""}
                      key={i}
                      name={t(item.key)}
                      table="orderItem"
                      column={item.value}
                      type={item.type}
                      search={filterSearch}
                      ref={refArray[i]}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                      handleIconClicked={(direction) =>
                        handleArrowIconClicked(i, direction)
                      }
                      toExpand={
                        item.value === "productName" ||
                          item.value === "deliveryAddress"
                          ? () => setAllExpanded(!allExpanded)
                          : undefined
                      }
                      isExpanded={allExpanded}
                    />
                  )
              )}
              {user.role === "admin" && <th>Actions</th>}
            </tr>
          </thead>
          {!tableLoading && orders && orders.length > 0 && (
            <tbody>
              {orders.map((item, index) => (
                <tr key={index}>
                  {tableObj?.map(
                    (i, index) =>
                      (["admin", "accountant"].includes(role) ||
                        i.value !== "price") && (
                        <td key={index} className={i.disabled ? "d-none" : ""}>
                          {i.value === "createdAt" ? (
                            <>{moment(item.createdAt).format("DD/MM/YY HH:mm")}</>
                          ) : i.value === "productName" ? (
                            <TruncateCell
                              text={item.productName}
                              maxLength={
                                allExpanded ? item.productName.length : 20
                              }
                            />
                          ) : i.value === "deliveryAddress" ? (
                            <TruncateCell
                              text={item.deliveryAddress}
                              maxLength={allExpanded ? item.deliveryAddress : 10}
                            />
                          ) : i.value === "price" &&
                            ["admin", "accountant"].includes(role) ? (
                            <div>
                              {!!item.price ? (
                                <div>
                                  {toggleCurr ? (
                                    <div>
                                      {item.priceInINR} INR <br />
                                    </div>
                                  ) : (
                                    <div>
                                      {item.price}{" "}
                                      {item.client.Wallet.currency.code} <br />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                "-"
                              )}
                            </div>
                          ) : i.value === "id" ? (
                            <div>
                              <Link to={`/orderHistory/${item.orderId}`}
                                role="button"
                                className={i.disabled ? "d-none" : ""}
                              >
                                {item.id}
                              </Link>
                              {!!item?.historyMetadata.length &&
                                ["admin", "accountant"].includes(role) && (
                                  <i
                                    className="fa-solid fa-circle-info ms-2 download_Link"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOrderItemHistoryInfo(true);
                                      setInfoObj(item);
                                    }}
                                  ></i>
                                )}
                            </div>
                          ) : i.value === "trackingId" ? (
                            <TrackingDialog item={item} />
                          ) : (
                            <>{item[i.value]}</>
                          )}
                        </td>
                      )
                  )}
                  {["admin", "dispatch_manager"].includes(user.role) && (
                    <td>
                      <Dropdown data-bs-theme="dark">
                        <Dropdown.Toggle
                          id="dropdown-button-dark-example1"
                          variant="secondary"
                        >
                          Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {["admin", "dispatchManager"].includes(role) && (
                            <>
                              <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  updateItem(item);
                                }}
                              >
                                <i className="fa-regular fa-pen-to-square"></i>
                                &nbsp;Update
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleChangeOrderItemBtn(item)}
                                disabled={changeNameLoading}
                              >
                                <i className="fa-solid fa-shuffle"></i>
                                &nbsp;Change
                              </Dropdown.Item>
                            </>
                          )}
                          <Dropdown.Item as="button">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              label="Ignore alerts"
                              checked={item.ignoreNotification}
                              onChange={() => {
                                handleIgnoreAlertsToggle(item.id, !item.ignoreNotification);
                              }}
                            />
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </InfiniteScroll>
      {tableLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        orders.length === 0 && (
          <div className="text-center">
            <h4>No Orders Yet</h4>
          </div>
        )
      )}
      <Modal show={updateModal} onHide={handleUpdateModal}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Update Order Item {updateData.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Shipment Type</Form.Label>
              <Form.Select
                aria-label="Shipment Type"
                value={updateData.shipmentType}
                onChange={(e) =>
                  setUpdateData({ ...updateData, shipmentType: e.target.value })
                }
              >
                {
                  ShipmentTypeList.map((item) => (
                    <option key={item} value={item}>{item}</option>
                  ))
                }
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Consignee Name</Form.Label>
              <Form.Control
                type="text"
                required
                value={updateData.consigneeName}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    consigneeName: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Delivery Address</Form.Label>
              <Form.Control
                type="text"
                required
                value={updateData.deliveryAddress}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    deliveryAddress: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Pincode</Form.Label>
              <Form.Control
                type="text"
                required
                value={updateData.pincode}
                onChange={(e) =>
                  setUpdateData({ ...updateData, pincode: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                required
                value={updateData.country}
                onChange={(e) =>
                  setUpdateData({ ...updateData, country: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                pattern="[0-9]+"
                required
                value={updateData.phone}
                onChange={(e) =>
                  setUpdateData({ ...updateData, phone: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tracking Id</Form.Label>
              <Form.Control
                type="text"
                value={updateData.trackingId || undefined}
                onChange={(e) =>
                  setUpdateData({ ...updateData, trackingId: e.target.value })
                }
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleUpdateModal}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={() => handleSubmit()}>
            Save Changes
          </Button> */}
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* modal for table column reordering */}

      <Modal
        show={tableReorderModal}
        onHide={() => {
          setTableReorderModal(false);
          setTableReorderState(tableObj);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change table order and visibility setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {tableReorderState.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={`item-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="d-flex justify-content-between align-items-center cursor-move"
                        >
                          <div className="item-key-width fw-bold light_class">
                            {item.key}
                          </div>
                          <div>
                            <Form.Select
                              aria-label="Select Position"
                              onChange={(e) =>
                                handlePositionChange(
                                  index,
                                  Number(e.target.value)
                                )
                              }
                              value={tableReorderState.indexOf(item)}
                            >
                              {tableReorderState.map((item, i) => (
                                <option
                                  key={i}
                                  value={tableReorderState.indexOf(item)}
                                >
                                  {tableReorderState.indexOf(item) + 1}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="me-2">
                            <i
                              className={`light_class ${!item.disabled
                                ? "fa-solid fa-eye"
                                : "fa-solid fa-eye-slash"
                                }`}
                              role="button"
                              onClick={() => handleDisabledButton(item)}
                            ></i>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setTableReorderModal(false);
              setTableReorderState(tableObj);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleTableSetting}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for udpating name of the order item */}

      <Modal
        show={changeOrderItemNameModal}
        onHide={() => {
          setChangeOrderItemNameModal(!changeOrderItemNameModal);
          setChangeOrderItem({
            id: "",
            oldName: "",
            productName: "",
            isSubstitute: true,
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Ordered Item - {changeOrderItem.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>OrderItem name</Form.Label>
              <Form.Control
                type="email"
                placeholder="Name"
                value={changeOrderItem.oldName}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>New Item</Form.Label>
              <Form.Control
                type="email"
                placeholder=" New Item"
                autoFocus
                onChange={(e) =>
                  setChangeOrderItem({
                    ...changeOrderItem,
                    productName: e.target.value.toUpperCase().trim(),
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                id="substitute-switch"
                label="is Substitute"
                className="light_class"
                defaultChecked={changeOrderItem.isSubstitute}
                onChange={(e) =>
                  setChangeOrderItem({
                    ...changeOrderItem,
                    isSubstitute: e.target.checked,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setChangeOrderItemNameModal(!changeOrderItemNameModal);
              setChangeOrderItem({
                id: "",
                oldName: "",
                productName: "",
                isSubstitute: true,
              });
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleChangeOrderItem}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* orderItem history Info modal */}
      <Modal
        show={orderItemHistoryInfo}
        onHide={() => {
          setInfoObj(null);
          setOrderItemHistoryInfo(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>OrderItem History Info for {infoObj?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered>
            <thead>
              <tr>
                <th>Event</th>
                <th>Email</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {infoObj?.historyMetadata?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.event}</td>
                  <td>{item?.user?.email}</td>
                  <td>
                    {item?.createdAt
                      ? moment(item?.createdAt).format("DD/MM/YY HH:mm")
                      : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <hr />
          {infoObj ? (
            <HistoryComment
              selectedOrderItem={infoObj}
              setInfoObj={setInfoObj}
            />
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setInfoObj(null);
              setOrderItemHistoryInfo(false);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AdminOrderItemHistory;
