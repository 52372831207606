import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { getUniqueValues } from "../../Services/adminService";
import { useSelector } from "react-redux";
import { Button, Dropdown, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

const FilterToggle = forwardRef(({ children, onClick }, ref) => (
  <span
    role="button"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </span>
));

const FilterSearch = forwardRef(
  ({ name, table, column, type, search, keyValue, toExpand, orderBy, setOrderBy, className, optionsObj, isExpanded}, ref) => {
    const [options, setOptions] = useState(null);
    const [text, setText] = useState("");
    const [isFilter, setIsFilter] = useState(false);
    const [allExpanded, setAllExpanded] = useState(isExpanded ||false);
    const [dateObj, setDateObj] = useState({
      startDate: undefined,
      endDate: undefined,
    });
    const token = useSelector((state) => state.token);
    const [optionalObjValue, setOptionalObjValue] = useState("")
    const [selectedOption, setSelectedOption] = useState(null);
    const [jsonEqualDropDown,setJsonEqualDropDown] = useState(false)
    const thRef = useRef(null);

    useImperativeHandle(ref, () => ({
      resetFilter() {
        setIsFilter(false);
        setText("");
        setDateObj({
          startDate: undefined,
          endDate: undefined,
        });
      },
      setInitValue(type) {
        setIsFilter(true)
      }
    }));


    const handleClicked = async () => {
      try {
        if (!options) {
          const res = await getUniqueValues(token, { table, column });
          const values = res.data.uniqueValues;
          setOptions(values);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const handleSetOrderBy = (column) => {
      setOrderBy(prevState => ({
        [column]: prevState[column] === "asc" ? "desc" : "asc"
      }))
    }

    const removedColumn = (column, type, key) => {
      search({ column, key }, true)
      setIsFilter(false)
      if (type === "date") {
        setDateObj({
          startDate: undefined,
          endDate: undefined,
        });
      }
      if (type === "JSON_date") {
        setDateObj({
          startDate: undefined,
          endDate: undefined
        })
        setOptionalObjValue("")
      }
      if (type === "contains") {
        setText("")
      }
      setSelectedOption(null)
    }

    useEffect(() => {
      setAllExpanded(isExpanded)
    }, [isExpanded])

    return (
      <th className={`position-relative ${className || ""}`} ref={thRef}>
        <span onClick={setOrderBy && (() => handleSetOrderBy(column))} role={setOrderBy && "button"} >{name}</span>
        {
          orderBy && orderBy[column] && (orderBy[column] === "asc" ? (
            <i className="fa-solid fa-arrow-down"></i>
          ) : (
            <i className="fa-solid fa-arrow-up"></i>
          ))
        }
        {type === "equal" && (
          <>
            <Dropdown
              style={{ display: "inline-block", marginLeft: "5px" }}
              onClick={handleClicked}
            >
              <Dropdown.Toggle as={FilterToggle}>
                <i
                  style={{ color: isFilter ? "red" : "inherit" }}
                  className="fa-solid fa-filter m-0 p-0"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {options &&
                  options.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        search({ column, type, value: item[column] });
                        setIsFilter(true);
                        setSelectedOption(item)
                      }}
                      className={selectedOption === item ? "bg-info" : ""}
                    >
                      <span className="d-flex justify-content-between align-items-center">
                        {item[column] === null ? "null" : item[column]}
                        <Button
                          className={selectedOption === "!" + item[column] ? "bg-info" : ""}
                          variant="outline-primary"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            search({ column, type, value: "!" + item[column] });
                            setIsFilter(true);
                            setSelectedOption("!" + item[column]);
                            if (thRef.current) thRef.current.click();
                          }}
                        >
                          <i className="fa-solid fa-exclamation"></i>
                        </Button>
                      </span>
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        {type === "contains" && (
          <>
            <Dropdown style={{ display: "inline-block", marginLeft: "5px" }}>
              <Dropdown.Toggle as={FilterToggle}>
                <i
                  style={{ color: isFilter ? "red" : "inherit" }}
                  className="fa-solid fa-filter m-0 p-0"
                ></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Form.Control
                  autoFocus
                  className="mx-3 my-2 w-auto"
                  placeholder="Type to filter..."
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      search({ column, type, value: text });
                      setIsFilter(true);
                    }
                  }}
                />
                <Dropdown.Item
                  onClick={() => {
                    search({ column, type, value: text });
                    setIsFilter(true);
                  }}
                >
                  Search
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        {type === "date" && (
          <>
            <Dropdown style={{ display: "inline-block", marginLeft: "5px" }}>
              <Dropdown.Toggle as={FilterToggle}>
                <i
                  style={{ color: isFilter ? "red" : "inherit" }}
                  className="fa-solid fa-filter m-0 p-0"
                ></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <>
                  <DatePicker
                    className="date-picker-style mx-3 my-2 w-auto"
                    selected={dateObj.startDate}
                    onChange={(date) => {
                      if (date) {
                        date.setHours(0);
                        date.setMinutes(0);
                        date.setSeconds(0);
                      }
                      setDateObj({ ...dateObj, startDate: date });
                    }}
                    disabledKeyboardNavigation
                    placeholderText=" From Date"
                  />
                </>
                <>
                  <DatePicker
                    className="date-picker-style mx-3 my-2 w-auto"
                    selected={dateObj.endDate}
                    onChange={(date) => {
                      if (date) {
                        date.setHours(23);
                        date.setMinutes(59);
                        date.setSeconds(59);
                      }
                      setDateObj({ ...dateObj, endDate: date });
                    }}
                    disabledKeyboardNavigation
                    placeholderText=" To Date"
                  />
                </>
                <Dropdown.Item
                  eventKey="1"
                  className="mt-2"
                  onClick={() => {
                    search({ column, type, dateObj: dateObj });
                    setIsFilter(true);
                  }}
                >
                  Search
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        {type === "JSON_date" && (
          <>
            <Dropdown style={{ display: "inline-block", marginLeft: "5px" }}>
              <Dropdown.Toggle as={FilterToggle}>
                <i
                  style={{ color: isFilter ? "red" : "inherit" }}
                  className="fa-solid fa-filter m-0 p-0"
                ></i>
              </Dropdown.Toggle>

              <Dropdown.Menu className="px-2">
                {
                  optionsObj &&
                  <Dropdown onSelect={(selectedOption) => {
                    setOptionalObjValue(selectedOption);
                    search({ column, key: keyValue, value: optionsObj[selectedOption], type: "JSON_equals" });
                    setIsFilter(true);
                  }}>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {optionalObjValue ? optionalObjValue : 'Select Option'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="">Select Option</Dropdown.Item>
                      {Object.keys(optionsObj)?.map((item, index) => (
                        <Dropdown.Item key={index} eventKey={item}>{item}</Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                }

                <>
                  <DatePicker
                    className="date-picker-style mx-3 my-2 w-auto"
                    selected={dateObj.startDate}
                    onChange={(date) => {
                      if (date) {
                        date.setHours(0);
                        date.setMinutes(0);
                        date.setSeconds(0);
                      }
                      setDateObj({ ...dateObj, startDate: date.getTime() });
                    }}
                    disabledKeyboardNavigation
                    placeholderText=" From Date"
                  />
                  <DatePicker
                    className="date-picker-style mx-3 my-2 w-auto"
                    selected={dateObj.endDate}
                    onChange={(date) => {
                      if (date) {
                        date.setHours(23);
                        date.setMinutes(59);
                        date.setSeconds(59);
                      }
                      setDateObj({ ...dateObj, endDate: date.getTime() });
                    }}
                    disabledKeyboardNavigation
                    placeholderText=" To Date"
                  />
                </>
                <Dropdown.Item
                  eventKey="1"
                  className="mt-2"
                  onClick={() => {
                    search({ column, type, dateObj, key: keyValue });
                    setIsFilter(true);
                  }}
                >
                  Search
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        {type === "boolean" && (
          <>
            <Dropdown
              style={{ display: "inline-block", marginLeft: "5px" }}
            // onClick={handleClicked}
            >
              <Dropdown.Toggle as={FilterToggle}>
                <i
                  style={{ color: isFilter ? "red" : "inherit" }}
                  className="fa-solid fa-filter m-0 p-0"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    search({ column, type, value: true });
                    setIsFilter(true);
                  }}
                >
                  True
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    search({ column, type, value: false });
                    setIsFilter(true);
                  }}
                >
                  False
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        {type === "JSON_equals" && (
          <>
            <Dropdown
              style={{ display: "inline-block", marginLeft: "5px" }}
            >
              <Dropdown.Toggle as={FilterToggle}>
                <i
                  style={{ color: isFilter ? "red" : "inherit" }}
                  className="fa-solid fa-filter m-0 p-0"
                  onClick={()=>setJsonEqualDropDown(!jsonEqualDropDown)}
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  optionsObj &&
                  Object.keys(optionsObj).map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        search({ column, type, value: optionsObj[item], key: keyValue });
                        setIsFilter(true);
                        setSelectedOption(item)
                        setJsonEqualDropDown(false)
                      }}
                      className={selectedOption === item ? "bg-info" : ""}
                    >
                      <span className="d-flex justify-content-between align-items-center">
                        {item}
                        <Button
                          className={selectedOption === "!" + item ? "bg-info" : ""}
                          size="sm"
                          variant="outline-primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            search({ column, type, value: "!" + optionsObj[item], key: keyValue });
                            setIsFilter(true);
                            setSelectedOption("!" + item);
                            setJsonEqualDropDown(false)
                            if (thRef.current) thRef.current.click();
                          }}
                        >
                          {" "}<i className="fa-solid fa-exclamation"></i>{" "}
                        </Button>
                      </span>
                    </Dropdown.Item>
                  ))
                }
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        {isFilter && <i className="fa-solid fa-xmark position-absolute top-0 end-0" role="button" onClick={() => {
          if (keyValue) {
            removedColumn(column, type, keyValue)
          } else {
            removedColumn(column, type)
          }
        }}></i>}
        {toExpand && (
          <span
            onClick={() => {
              setAllExpanded(!allExpanded)
              toExpand();
            }}
            className="readButton"
          >
            <u>{allExpanded ? "Read Less" : "Read More"}</u>
          </span>
        )}
      </th>
    );
  }
);

export default FilterSearch;
