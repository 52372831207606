import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { getArchiveEntities } from "../../../Services/adminService";
import { useSelector } from "react-redux";
import { errorToast } from "../../../Services/toastService";
import FilterSearch from "../../../SharedComponents/filterSearch/filterSearch";
import * as XLSX from "xlsx";
import Pagination from "react-bootstrap/Pagination";
import TruncateCell from "../../../SharedComponents/truncateCell/truncateCell";
import moment from "moment";
import "./archivePriceEntities.css";

const ArchivePriceEntities = () => {
  const token = useSelector((state) => state.token);
  const [allExpanded, setAllExpanded] = useState(false);
  const [entitiesTable, setEntitiesTable] = useState(null);
  const [entitiesTableCount, setEntitiesTableCount] = useState(null);
  const [filterArr, setFilterArr] = useState([]);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState({ id: "desc" });
  const filterSearchRef1 = useRef(null);
  const filterSearchRef2 = useRef(null);
  const filterSearchRef3 = useRef(null);
  const filterSearchRef4 = useRef(null);
  const filterSearchRef5 = useRef(null);
  const filterSearchRef6 = useRef(null);
  const filterSearchRef7 = useRef(null);
  let items = [];
  let paginationNum = [10, 25, 50];
  paginationNum.forEach((number) => {
    items.push(
      <Pagination.Item
        key={number}
        active={number === limit}
        onClick={() => setLimit(number)}
      >
        {number}
      </Pagination.Item>
    );
  });

  const downloadTableData = () => {
    if (!entitiesTable) {
      errorToast("Table is empty");
      return;
    }
    let headers = [
      "productId",
      "productName",
      "countryId",
      "price",
      "priceGroupId",
      "shipmentTypeId",
      "effectiveDate",
    ];

    const data = [
      headers,
      ...entitiesTable.map((product) => {
        return headers.map((header) => product[header]);
      }),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
    const excelData = XLSX.write(workbook, { type: "binary" });

    const blob = new Blob([stringToArrayBuffer(excelData)], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "archive-price-entities.xlsx";

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
  };

  const stringToArrayBuffer = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const getEntitiesData = async () => {
    try {
      const res = await getArchiveEntities(
        token,
        filterArr,
        limit,
        null,
        orderBy
      );
      setEntitiesTable(res.data.data);
      setEntitiesTableCount(res.data.count);
    } catch (err) {
      return errorToast(err.response?.data?.message || "Something went wrong");
    }
  };

  const filterSearch = async (obj, isSelected) => {
    let current = filterArr;
    current = current.filter((item) => item.column !== obj.column);
    if (isSelected) {
      setFilterArr(current);
    } else {
      current.push(obj);
      setFilterArr(current);
    }
  };

  useEffect(() => {
    getEntitiesData();
    // eslint-disable-next-line
  }, [filterArr, orderBy, limit]);

  const handleLoadMore = async () => {
    try {
      const res = await getArchiveEntities(
        token,
        filterArr,
        limit,
        entitiesTable.length,
        orderBy
      );
      setEntitiesTableCount(res.data.count);
      let table = [...entitiesTable];
      table.push(...res.data.data);
      setEntitiesTable(table);
    } catch (err) {
      return errorToast(err.response?.data?.message || "Something went wrong");
    }
  };

  const resetBtnHandler = () => {
    setFilterArr([]);
    setOrderBy({ id: "desc" });
    setLimit(10);
    filterSearchRef1.current && filterSearchRef1.current.resetFilter();
    filterSearchRef2.current && filterSearchRef2.current.resetFilter();
    filterSearchRef3.current && filterSearchRef3.current.resetFilter();
    filterSearchRef4.current && filterSearchRef4.current.resetFilter();
    filterSearchRef5.current && filterSearchRef5.current.resetFilter();
    filterSearchRef6.current && filterSearchRef6.current.resetFilter();
    filterSearchRef7.current && filterSearchRef7.current.resetFilter();
  };

  return (
    <Container>
      <h4 className="mb-4">Archive Price Entities</h4>
      <div className="mb-3">
        <div className="mb-3 d-flex gap-2">
          <Button variant="outline-secondary" onClick={downloadTableData}>
            <i className="fa-solid fa-download"></i> View
          </Button>
          <Button variant="outline-danger" onClick={resetBtnHandler}>
            <i className="fa-solid fa-rotate-left"></i>
          </Button>
        </div>
        {!!entitiesTable ? (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <FilterSearch
                    name="Product ID"
                    table="archivePriceEntities"
                    column="productId"
                    type="contains"
                    search={filterSearch}
                    ref={filterSearchRef1}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FilterSearch
                    name="Product Name"
                    table="archivePriceEntities"
                    column="productName"
                    type="contains"
                    search={filterSearch}
                    ref={filterSearchRef6}
                    toExpand={() => setAllExpanded(!allExpanded)}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FilterSearch
                    name="Country"
                    table="archivePriceEntities"
                    column="countryId"
                    type="equal"
                    search={filterSearch}
                    ref={filterSearchRef2}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FilterSearch
                    name="Price(INR)"
                    table="archivePriceEntities"
                    column="price"
                    // type="equal"
                    search={filterSearch}
                    ref={filterSearchRef3}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FilterSearch
                    name="Price Group"
                    table="archivePriceEntities"
                    column="priceGroupId"
                    type="equal"
                    search={filterSearch}
                    ref={filterSearchRef4}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FilterSearch
                    name="Shipment Type"
                    table="archivePriceEntities"
                    column="shipmentTypeId"
                    type="equal"
                    search={filterSearch}
                    ref={filterSearchRef5}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FilterSearch
                    name="Effective Date"
                    table="archivePriceEntities"
                    column="effectiveDate"
                    type="date"
                    search={filterSearch}
                    ref={filterSearchRef7}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                </tr>
              </thead>
              <tbody>
                {entitiesTable?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.productId}</td>
                    <td>
                      <TruncateCell
                        text={item.productName}
                        maxLength={allExpanded ? item.name?.length : 15}
                      />
                    </td>
                    <td>{item.countryId}</td>
                    <td>{item.price}</td>
                    <td>{item.priceGroupId}</td>
                    <td>{item.shipmentTypeId}</td>
                    <td>{moment(item.effectiveDate).format("DD/MM/YYYY")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-2">
                {entitiesTable.length}/{entitiesTableCount}
              </div>
              <div className="mt-2">
                <Pagination>{items}</Pagination>
              </div>
            </div>
            {entitiesTable.length !== entitiesTableCount && (
              <div className="text-center">
                <Button onClick={handleLoadMore}>Load More</Button>
              </div>
            )}
          </>
        ) : (
          "No data Available"
        )}
      </div>
    </Container>
  );
};

export default ArchivePriceEntities;
