import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  InputGroup,
  Modal,
  Pagination,
  Table,
} from "react-bootstrap";
import {
  addProductSku,
  boxNoList,
  deleteSku,
  getAllClients,
  getProductList,
  skuList,
} from "../../../Services/adminService";
import { useSelector } from "react-redux";
import Select from "react-select";
import { errorToast, successToast } from "../../../Services/toastService";
import FilterSearch from "../../../SharedComponents/filterSearch/filterSearch";
import { getPriceSlabList } from "../../../Services/shipmentService";

const ProductSku = () => {
  const token = useSelector((state) => state.token);
  const [addSkuModal, setAddSkuModal] = useState(false);
  const [productList, setProductList] = useState([]);
  const [reqObj, setReqObj] = useState({
    productId: "",
    quantity: "",
    unitOfMeasure: "",
    boxNo: "",
  });
  const [uomList, setUomList] = useState({});
  const [options, setOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [searchObj, setSearchObj] = useState([]);
  const [orderBy, setOrderBy] = useState({ createdAt: "desc" });
  const [clientFilterModal, setClientFilterModal] = useState(false);
  const [clientFilterInput, setClientFilterInput] = useState({
    id: "",
    clientName: "",
    slabID: "",
    shipmentName: "",
  });
  const [clientFilter, setClientFilter] = useState(
    localStorage.getItem("skuClientFilter")
      ? JSON.parse(localStorage.getItem("skuClientFilter"))
      : []
  );
  const [clientList, setClientList] = useState([]);
  const [codeList, setCodeList] = useState([]);
  const [boxNumberList, setBoxNumberList] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [priceBreakdownValue, setPriceBreakdownValue] = useState([]);
  const [priceBreakdownModal, setPriceBreakdownModal] = useState(false);
  const filterSearchRef1 = useRef(null);
  const [lastSelectedSku, setLastSelectedSku] = useState(() => {
    const saved = localStorage.getItem('lastSelectedSku');
    return saved ? JSON.parse(saved) : null;
  });

  let items = [];
  let paginationNum = [10, 25, 50];
  paginationNum.forEach((number) => {
    items.push(
      <Pagination.Item
        key={number}
        active={number === limit}
        onClick={() => setLimit(number)}
      >
        {number}
      </Pagination.Item>
    );
  });

  const handleModalClose = () => {
    if (reqObj.productId && reqObj.boxNo) {
      const skuToSave = {
        productId: reqObj.productId,
        boxNo: reqObj.boxNo,
        productName: options.find(opt => opt.value === reqObj.productId)?.label || ''
      };
      localStorage.setItem('lastSelectedSku', JSON.stringify(skuToSave));
      setLastSelectedSku(skuToSave);
    }
    
    setReqObj({
      productId: "",
      quantity: "",
      unitOfMeasure: "",
      boxNo: ""
    });
    setAddSkuModal(false);
  };

  const handleOpenModal = () => {
    if (lastSelectedSku) {
      setReqObj({
        productId: lastSelectedSku.productId,
        quantity: "",
        unitOfMeasure: options.find(opt => opt.value === lastSelectedSku.productId)?.saleUOM || "",
        boxNo: lastSelectedSku.boxNo
      });
    }
    setAddSkuModal(true);
  };

  const getUom = () => {
    try {
      if (reqObj.productId) {
        const data = productList.filter((item) => item.id === reqObj.productId);
        const uom = data[0].UOMs;
        setUomList(uom);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUom();
    // eslint-disable-next-line
  }, [reqObj.productId]);

  useEffect(() => {
    localStorage.setItem("skuClientFilter", JSON.stringify(clientFilter));
    // eslint-disable-next-line
  }, [clientFilter]);

  const getProductData = async () => {
    try {
      const res = await getProductList(token);
      if (res.status === 200) {
        setProductList(res.data);
        let data = [];
        res.data.map((item) => {
          return data.push({
            value: item.id,
            label: item.name,
            saleUOM: item.saleUOM,
          });
        });
        setOptions(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSkuList = async () => {
    try {
      const res = await skuList(
        token,
        searchObj,
        { limit },
        orderBy,
        clientFilter
      );
      setTableData(res.data.productSKUs);
      setCount(res.data.productSKUsCount);
      let keys = {};
      res.data.productSKUs.forEach((item) => {
        if (Object.keys(item).length > Object.keys(keys).length) {
          keys = item;
        }
      });
      const newHeader = Object.keys(keys).filter(
        (item) => item.startsWith("|") && !item.endsWith("|priceBreakdown|")
      );
      setTableHeaders(newHeader);
    } catch (error) {
      console.log(error);
    }
  };

  const getBoxNoList = async () => {
    const res = await boxNoList(token);
    setBoxNumberList(res.data);
  };

  useEffect(() => {
    getProductData();
    getBoxNoList();
    // getSkuList(token);
    userData();
    getPriceSlabs();
    // eslint-disable-next-line
  }, []);

  const userData = async () => {
    try {
      const res = await getAllClients(token);
      setClientList(res.data.clients);
    } catch (err) {
      return errorToast("Something went wrong");
    }
  };

  const getPriceSlabs = async () => {
    try {
      const res = await getPriceSlabList(token);
      setCodeList(res.data.data);
    } catch (error) {
      errorToast("something went wrong");
    }
  };

  const filterSearch = async (obj, isSelected) => {
    let current = searchObj;
    current = current.filter((item) => item.column !== obj.column);
    if (isSelected) {
      setSearchObj(current);
    } else {
      current.push(obj);
      setSearchObj(current);
    }
  };

  const handleClientFilter = () => {
    setClientFilterModal(false);
    setClientFilterInput({
      id: "",
      slabID: "",
    });
  };

  const handleDeleteBtn = async (id) => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm("Are you sure you want to delete this SKU?")) return;
      const res = await deleteSku(token, id);
      if (res.status === 200) {
        successToast("SKU deleted successfully");
        setTableData(tableData.filter((item) => item.id !== id));
        setCount(count - 1);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    }
  };

  useEffect(() => {
    getSkuList(token);
    // eslint-disable-next-line
  }, [searchObj, limit, orderBy]);

  const handleAddBtn = async () => {
    try {
      if (!reqObj.productId || !reqObj.quantity || !reqObj.unitOfMeasure) {
        return errorToast("All Fields are Required");
      }
      const res = await addProductSku(token, reqObj);
      if (res.status === 200) {
        successToast("SKU added successfully");
        handleModalClose();
        getSkuList();
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    }
  };

  const clientFilterTableHandle = () => {
    if (
      !clientFilterInput.id ||
      !clientFilterInput.slabID ||
      !clientFilterInput.id.length ||
      !clientFilterInput.slabID.length
    ) {
      return errorToast("Fields are required");
    }
    const existingItem = clientFilter?.find((item) => {
      return (
        item.id === clientFilterInput.id &&
        item.slabID === clientFilterInput.slabID
      );
    });

    if (existingItem) {
      return errorToast("Data already exists");
    }
    let newTable = [...clientFilter, clientFilterInput];
    setClientFilter(newTable);
    setClientFilterInput({
      id: "",
      slabID: "",
    });
  };

  const handleLoadMore = async () => {
    try {
      let newObj = { ...reqObj, offSet: tableData.length };
      const response = await skuList(token, searchObj, newObj, orderBy);
      let table = [...tableData];
      table.push(...response.data.productSKUs);
      setTableData(table);
      setCount(response.data.productSKUsCount);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <h4 className="my-4">Product SKUs</h4>
      <Button
        onClick={handleOpenModal}
      >
        + Add SKUs
      </Button>
      <Button
        variant="outline-secondary"
        id="button-addon2"
        onClick={() => setClientFilterModal(true)}
        className="m-1"
      >
        Client Filter
      </Button>

      {tableData && !!tableData.length ? (
        <Table bordered responsive className="mt-4">
          <thead>
            <tr>
              <FilterSearch
                name="Product ID"
                table="productSKU"
                column="productId"
                type="contains"
                search={filterSearch}
                ref={filterSearchRef1}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Box</th>
              <th>Unit of measure</th>
              <th>Action</th>
              {tableHeaders?.map((item) => (
                <th key={item}>{item.slice(1)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={index}>
                <td>{item.productId}</td>
                <td>{item.product?.name || "-"}</td>
                <td>{item.quantity}</td>
                <td>{item.boxNo}</td>
                <td>
                  {item.unitOfMeasure} (
                  {item.product?.UOMs[item.unitOfMeasure] || "-"})
                </td>
                <td>
                  <Button
                    className="me-2"
                    size="sm"
                    variant="danger"
                    onClick={() => handleDeleteBtn(item.id)}
                  >
                    Delete
                  </Button>
                </td>
                {tableHeaders?.map((k) =>
                  item[k] !== "-" ? (
                    <th
                      key={k}
                      role="button"
                      className="text-primary"
                      onClick={() => {
                        setPriceBreakdownValue(item[k + "|priceBreakdown|"]);
                        setPriceBreakdownModal(true);
                      }}
                    >
                      {item[k]}
                    </th>
                  ) : (
                    <th key={k}>{item[k]}</th>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center">No Data Available</div>
      )}
      {tableData && !!tableData.length && tableData.length > 0 && (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {tableData.length} / {count}
          </div>
          <div>
            <Pagination>{items}</Pagination>
          </div>
        </div>
      )}
      <div className="text-center my-2">
        {tableData && count > tableData.length ? (
          <Button
            variant="dark"
            onClick={() => {
              setReqObj({ ...reqObj, offSet: tableData.length });
              handleLoadMore();
            }}
          >
            Load more
          </Button>
        ) : null}
      </div>

      <Modal show={addSkuModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create SKU</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddBtn(e);
          }}
        >
          <Modal.Body>
            <Form.Group className="mb-3" required>
              <Form.Label>Select Product</Form.Label>
              <Select
                options={options}
                value={options.find(opt => opt.value === reqObj.productId) || null}
                onChange={(e) => {
                  setReqObj({
                    ...reqObj,
                    productId: e.value,
                    unitOfMeasure: e.saleUOM,
                  });
                }}
                required
              />
            </Form.Group>
            {reqObj.productId && (
              <>
                <Form.Group className="mb-3" required>
                  <Form.Label>Sale UOM</Form.Label>
                  <Form.Select
                    value={reqObj.unitOfMeasure}
                    disabled
                    onChange={(e) => {
                      setReqObj({ ...reqObj, unitOfMeasure: e.target.value });
                    }}
                    required
                  >
                    <option value="">Select UOM</option>
                    {Object.keys(uomList).length &&
                      Object.keys(uomList).map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Label>Quantity</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    {uomList[reqObj.unitOfMeasure]} x
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder=" Add Quantity"
                    value={reqObj.quantity}
                    onChange={(e) => {
                      setReqObj({ ...reqObj, quantity: e.target.value });
                    }}
                    required
                  />
                  <InputGroup.Text>
                    {reqObj.quantity * uomList[reqObj.unitOfMeasure]}
                  </InputGroup.Text>
                </InputGroup>
              </>
            )}
            <Form.Group className="mb-3" required>
              <Form.Label>Select Box Number</Form.Label>
              <Form.Select
                value={reqObj.boxNo}
                onChange={(e) => {
                  setReqObj({ ...reqObj, boxNo: e.target.value });
                }}
                required
              >
                <option value="">Select Box Number</option>
                {boxNumberList.map((item) => (
                  <option 
                    key={item.id} 
                    value={item.id}
                  >
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={clientFilterModal} onHide={handleClientFilter}>
        <Modal.Header closeButton>
          <Modal.Title>Client Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3 ">
            <Form.Label>Client</Form.Label>
            <Form.Select
              aria-label="Client list"
              onChange={(e) => {
                const selectedClientId = e.target.value;
                const selectedClient = clientList.find(
                  (item) => item.id === selectedClientId
                );
                setClientFilterInput({
                  ...clientFilterInput,
                  id: selectedClientId,
                  clientName: selectedClient.name,
                });
              }}
              value={clientFilterInput.id}
            >
              <option value="">Select Client</option>
              {clientList?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Select
              aria-label="Shipment Code List"
              onChange={(e) => {
                const selectedOption = codeList.find(
                  (item) => item.id === e.target.value
                );
                if (selectedOption) {
                  setClientFilterInput({
                    ...clientFilterInput,
                    slabID: selectedOption.id,
                    shipmentName: `${selectedOption.country.name}, ${selectedOption.shipmentType.name}`,
                  });
                }
              }}
              value={clientFilterInput.slabID}
            >
              <option value="">Select Shipment Type</option>
              {codeList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.country.name}, {item.shipmentType.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Button onClick={clientFilterTableHandle}>Add</Button>
          {!!clientFilter.length && (
            <Table bordered hover className="mt-4">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>slabID</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {clientFilter.map((item, index) => (
                  <tr key={index}>
                    <td>{item.clientName}</td>
                    <td>{item.shipmentName}</td>
                    <td>
                      <Button
                        onClick={() => {
                          let filteredData = [...clientFilter];
                          filteredData.splice(index, 1);
                          setClientFilter(filteredData);
                        }}
                        variant="outline-danger"
                        size="sm"
                      >
                        <i className="fa-solid fa-x"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClientFilter}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              getSkuList(token);
              handleClientFilter();
            }}
            disabled={!clientFilter.length}
          >
            Search
          </Button>
        </Modal.Footer>
      </Modal>

      {/* price breakdown modal */}
      <Modal
        show={priceBreakdownModal}
        onHide={() => {
          setPriceBreakdownValue([]);
          setPriceBreakdownModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Price Breakdown</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {priceBreakdownValue.map((item, i) => (
                  <tr key={i}>
                    <td>{item[0]}</td>
                    <td>{item[1]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setPriceBreakdownValue([]);
              setPriceBreakdownModal(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ProductSku;
